/* BEGIN TRANSLATION */

const message = {
    "login-failed": "登录失败，请稍后再试",
    "login-required": "请先登录账号",
    "discard-confirmation": "您确定要放弃当前更改吗？",
    "cancel-order1": "您确定要取消所选的订单吗？",
    "cancel-order2": "您确定要取消所选的订单吗（{0}）？",
    "reject-order": "您确定要拒绝所选的订单吗？",
    "finalize-order": "您确定要总结所选的订单吗？",
    "delivered-order": "您确定要将状态更改为已运送吗？",
    "reverse-order": "您确定要撤消所选的订单吗？",
    "finalize-payment": "您确定要总结所选的付款吗？",
    "approve-payment": "您确定要批准所选的付款吗？",
    "cancel-payment": "您确定要取消所选的付款吗？",
    "reject-payment": "您确定要拒绝所选的付款吗？",
    "delivered-payment": "您确定要将状态更改为已运送吗？",
    "refunded-payment": "您确定要将状态更改为已退款吗？",
    "approve-transfer": "您确定要批准所选的转让吗？",
    "cancel-transfer": "您确定要取消所选的转让吗？",
    "record-saved": "记录已成功保存",
    "record-deleted": "记录已成功删除",
    "invalid-mode": "无效的模式",
    "record-not-found": "找不到记录",
    "no-address-added": "未添加地址",
    "address-city-required": "地址城市不可为空",
    "delete-record1": "您确定要删除所选的记录吗？",
    "delete-record2": "您确定要删除所选的记录吗（{0}）？",
    "delete-record3": "您确定要删除当前记录吗？",
    "not-logged-in": "您尚未登录或会话超时，请再次登录",
    authenticating: "正在验证，请稍候...",
    "load-comment-failed": "无法加载评论，正在重试...",
    "link-facebook-confirmation": "您确定要连接到您的Facebook帐户吗？连接后不可撤消此操作。",
    "link-account-confirmation": "您确定要连接到“{0}”吗？连接后不可撤销此操作。",
    "facebook-not-linked": "您尚未连接到您的Facebook帐户，请连接帐户再重试。",
    "account-not-selected": "请选择一个帐户以继续。",
    "live-session-nothing-selected": "请选择直播会话以继续。",
    "link-account-successful": "Facebook帐户已成功连接。",
    "refresh-token-successful": "由于Facebook会话已失效，现已刷新。",
    "no-account-found": "找不到可用的Facebook帐户。",
    "no-video-found": "找不到可用的视频。",
    "video-not-selected": "请选择一个视频以继续。",
    "nothing-selected": "请选择一个记录以继续。",
    "selected-video-confirmation": "您确定要使用在“{1}”时直播的视频“{0}”吗？",
    "live-session-stop-confirmation": "您确定要立即结束当前会话吗？",
    "live-session-delete-confirmation": "您确定要删除当前会话吗？",
    "live-session-product-not-introducing": "现在没有直播会话产品正在介绍中, 使用默认设定？",
    "order-accepted": "订单已接收 ({0}, {1}, {2})",
    "key-in-order-quantity": "请输入订购数量",
    "invalid-number": "请输入一个有效的数字并且不能超过零",
    "cart-quantity": "订单数量/结帐数量",
    "shipping-not-selected": "请选择运送地址。",
    "country-nothing-selected": "未选择任何国家",
    "checkout-nothing-selected": "请选择要结帐的订单。",
    "transfer-nothing-selected": "请选择要转让的订单。",
    "state-nothing-selected": "未选择状态",
    "city-nothing-selected": "未选择任何城市",
    "please-select-receipt": "请选择要上传的收据。",
    "payment-pending-approval": "感谢您的付款，您的付款{0}正在等待批准。",
    "transfer-pending-approval": "收到转让请求，{0}正在等待批准。",
    "key-in-cancel-quantity": "请输入取消数量",
    "shipping-fee-pricing-description": "每{0} g的重量将定为价格{1}。",
    "clipboard-copied": "已复制到剪贴板。",
    "customer-group-event-conflict": "当前事件设置与其他设置冲突。",
    "session-closing-all-notified": "已通知所有客户。",
	"live-session-comment-date1": "大约{0}秒钟前",
	"live-session-comment-date2": "大约{0}分钟前",
	"video-type-not-defined": "未定义视频类型",
	"invalid-facebook-account-id": "无效的Facebook账户",
	"order-reverted": "订单已还原",
	"revert-last-order-confirmation": "您确定要还原订单({0}, {1}, {2})？",
	"default-product-opened-successfully": "默认产品已成功使用",    
    "profile-close-account": {
        "w1": "请记得关闭账号将把您的用户资料删除并且无法恢复已删除的账号。",
        "w2": "如果想继续删除请输入‘",
        "w3": "’以便继续下去。",
        "alert": "请输入‘关闭账号’如果您想继续删除您的用户资料。"
    }
};

/* ENDED TRANSLATION */
export { message };